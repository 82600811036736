.wrapper {
  display: block;
  width: 100%;

  color: var(--color-black);

  line-height: 1.5;
}

.container {
  position: relative;

  width: 100%;
  height: 0;
  padding-bottom: 114%;

  border-radius: 10px;

  &::before {
    position: absolute;

    display: block;
    padding: var(--space-xs);

    border: 1px solid transparent;
    border-radius: 10px;

    transition: border-color 200ms;

    content: '';
    inset: var(--space-xs-neg);
  }
}

.image {
  position: absolute;
  inset: 0;
}

.text {
  position: absolute;
  inset: 0;

  padding: var(--space-n);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--space-xs);
}

.title {
  font-weight: 500;
  font-size: 30px;
  font-family: var(--font-hero);
}

.subtitle {
  font-size: var(--size-m);
}

.check {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 20px;
  height: 20px;

  background-color: var(--color-white);
  border: 1px solid var(--color-dark-gray);
  border-radius: 4px;
  opacity: 0;

  transition: border-color 200ms, background-color 200ms, opacity 200ms;
}

.mark {
  width: 13px;
  height: 10px;

  transition: stroke 200ms;

  fill: none;
  stroke: var(--color-dark-gray);
  stroke-width: 2px;
}

@media (--desktop-m) {
  .text {
    padding: var(--space-s);
  }

  .title {
    font-size: var(--size-xl);
  }

  .subtitle {
    font-size: var(--size-n);
  }
}

@media (--mobile-m) {
  .container::before {
    padding: 7.5px;
    inset: -7.5px;
  }

  .text {
    padding: var(--space-xs);
  }

  .header {
    margin-bottom: var(--space-xxs);
  }

  .title {
    font-size: var(--size-m);
  }

  .subtitle {
    font-size: var(--size-s);
  }
}

@media (hover: hover) {
  .container:hover .check {
    opacity: 1;
  }
}

.container.selected {
  &::before {
    border-color: var(--color-grafit);
  }

  & .check {
    background-color: var(--color-grafit);
    border-color: var(--color-grafit);
    opacity: 1;
  }

  & .mark {
    stroke: var(--color-white);
  }
}
