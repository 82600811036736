.slide {
  margin-right: 35px;

  &:first-child {
    margin-left: auto;
  }

  &:last-child {
    margin-right: auto;
  }
}
