.container {
  composes: main from 'containers';
}

.titleWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-xs);
  align-items: baseline;

  line-height: 1.5;
}

.title {
  font-weight: 500;
  font-size: var(--size-b);
}

.priceMin {
  font-size: var(--size-m);
}

.list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px 30px;
  padding-bottom: var(--space-b);

  border-bottom: 1px solid var(--color-medium-gray);
}

@media (--desktop-m) {
  .title {
    font-size: var(--size-xxl);
  }
}

@media (--desktop) {
  .list {
    grid-template-columns: repeat(3, 1fr);
    gap: 50px 20px;
  }

  .title {
    font-size: var(--size-xl);
  }

  .priceMin {
    font-size: var(--size-n);
    line-height: 1.5;
  }
}

@media (--mobile-m) {
  .container {
    overflow: hidden;
  }

  .title {
    font-size: var(--size-l);
  }

  .priceMin {
    font-size: var(--size-s);
  }

  .slide {
    min-width: 300px;
    max-width: 300px;
  }
}

@media (--mobile) {
  .title {
    font-size: var(--size-l);
  }

  .priceMin {
    font-size: var(--size-s);
  }
}

@media (--mobile-xxs) {
  .slide {
    min-width: 260px;
    max-width: 260px;
  }
}
