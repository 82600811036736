.catalog {
  display: block;

  &.hidePaginator {
    .moreWrapper {
      opacity: 0;
    }

    .loader {
      display: block;
    }
  }
}

.catalogWrapper {
  margin-top: var(--space-xxl);
}

.moreWrapper {
  display: flex;
  justify-content: center;
  margin-top: var(--space-b);
}

.moreButton {
  display: block;
  width: 100%;
  max-width: 260px;
}

.wrapperPaginator {
  display: flex;
  justify-content: center;
  margin-top: var(--space-b);
}

.filtersWrapper {
  composes: main from 'containers';
}

.bordered {
  border-top: 1px solid var(--color-medium-gray);
}

.rubrics {
  width: 100%;
}

.collectionFilters {
  margin-top: var(--space-b);
}

.rubricsContainer {
  composes: main from 'containers';

  display: flex;
  justify-content: center;
  width: 100%;
}

.rubricsWrapper {
  overflow: hidden;
}

.message {
  margin: var(--space-xxl) 0 var(--space-e);
}

.loader {
  position: relative;

  display: none;
  width: 100%;
  max-width: 300px;
  height: 4px;
  margin: 0 auto;
  margin-top: var(--space-e);
  overflow: hidden;

  background-color: var(--color-light-gray);

  &:after {
    position: absolute;
    top: 0;
    left: 0;

    box-sizing: border-box;

    height: 4px;

    background-color: var(--color-grafit);

    animation: hitZak 1s linear infinite alternate;

    content: '';
  }
}

@media (--desktop-m) {
  .message {
    margin-bottom: var(--space-xb);
  }
}

@media (--mobile-m) {
  .moreButton {
    max-width: 220px;
  }
}

@keyframes hitZak {
  0% {
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    right: 0;
    left: auto;

    width: 0;
  }
}
