.gallery {
  width: 100%;
  margin: var(--space-xxb-neg);
  padding: var(--space-xxb);
  overflow: hidden;
}

.slide {
  margin-right: var(--space-xxl);

  &:first-child {
    margin-left: auto;
  }

  &:last-child {
    margin-right: auto;
  }
}

.navigationLink {
  display: inline-block;
  padding: var(--space-xxs) 0;

  color: var(--color-dark-gray);
  font-size: var(--size-l);
  line-height: 1.5;
  vertical-align: top;

  border-bottom: 1px solid transparent;

  transition: all 0.2s;

  &:hover {
    color: var(--color-grafit);

    border-color: var(--color-grafit);
  }

  &.active {
    color: var(--color-black);

    border-color: var(--color-black);
  }
}

.arrowTriple {
  width: 23px;
  height: 20px;
  margin-right: 10px;
}

@media (--desktop-m) {
  .navigationLink {
    font-size: var(--size-m);
  }

  .gallery {
    margin: var(--space-xxl-neg);
    padding: var(--space-xxl);
  }
}

@media (--mobile) {
  .slide {
    margin-right: var(--space-n);
  }
}

@media (--mobile-m) {
  .navigationLink {
    font-size: var(--size-n);
  }

  .gallery {
    margin: var(--space-s-neg);
    padding: var(--space-s);
  }
}
