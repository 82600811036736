.gallery {
  --columns-count: 6;
  --space-between: 30px;
  composes: main from 'containers';

  overflow: hidden;
  padding-block: 10px;
}

.slide {
  width: calc(
    100% / var(--columns-count) - var(--space-between) / var(--columns-count) *
      (var(--columns-count) - 1)
  );
  margin-right: var(--space-between);

  &:last-child {
    margin-right: 0;
  }
}

@media (--desktop) {
  .slide {
    width: 190px;
  }
}

@media (--mobile-m) {
  .gallery {
    --space-between: var(--space-n);
  }

  .slide {
    width: 160px;
  }
}
