.sections {
  display: flex;
  flex-direction: column;
  gap: var(--space-xxb);
  margin-top: var(--space-xxl);
}

@media (--desktop-m) {
  .sections {
    gap: var(--space-xb);
  }
}

@media (--mobile-m) {
  .sections {
    gap: var(--space-b);
  }
}
